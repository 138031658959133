import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { getSmallestSizeImage } from '@/helpers/functions';
import { CATEGORY_PODCAST_AUDIO } from '@/config/constants';
import { useRoute } from 'vue-router';

export default function usePlayerState() {
  const store = useStore();

  const playerActive = computed(() => store.state.player.playerActive);
  const playerCollapsed = computed(() => store.state.player.playerCollapsed);
  const currentEpisode = computed(
    () => store.getters['player/getCurrentEpisode'] ?? {},
  );
  const currentCourse = computed(
    () => store.state.player.currentCourse ?? { media: [] },
  );
  const playerPlaylist = computed(() => store.state.player.playlist);
  const playerPlaylistStartAt = computed(
    () => store.state.player.playlistTrackStart,
  );
  const skipAutoplayId = computed(() => store.state.player.skipAutoplayId);
  const trackInfo = computed(() => store.state.player.trackInfo);

  const getBanner = (episode) => {
    if (!episode) return null;

    const episodeMedia =
      episode.media?.filter((m) => m.category !== CATEGORY_PODCAST_AUDIO) ?? [];

    return (
      getSmallestSizeImage(episodeMedia, 1)?.url ??
      getBanner(currentCourse.value) ??
      null
    );
  };

  const createEpisodeObject = (episode) => {
    if (!episode) return null;

    return {
      id: episode.id,
      banner: getBanner(episode),
    };
  };

  const allEpisodes = ref([]);
  watch(
    () => currentEpisode.value.id,
    () => {
      if (currentEpisode.value.id) {
        const episodeObjects = playerPlaylist.value.map((s) =>
          createEpisodeObject(s),
        );

        allEpisodes.value = episodeObjects;
      }
    },
    { immediate: true },
  );

  const indexOfCurrentEpisode = computed(() => {
    return allEpisodes.value.findIndex(
      (episode) => episode?.id === currentEpisode.value.id,
    );
  });

  const route = useRoute();
  const isMiniplayerClosable = computed(
    () => route.name !== 'AccreditationQuestions',
  );

  return {
    playerActive,
    playerCollapsed,
    currentEpisode,
    currentCourse,
    playerPlaylist,
    playerPlaylistStartAt,
    skipAutoplayId,
    trackInfo,
    indexOfCurrentEpisode,
    allEpisodes,
    getBanner,
    isMiniplayerClosable,
  };
}

import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default function usePlayerSwipe() {
  const store = useStore();
  const swipeY = ref(0);
  const swipeStartY = ref(0);
  const transformUp = ref(false);
  const translateY = computed(() => swipeY.value - swipeStartY.value);

  const handleSwipeStart = (e) => {
    swipeStartY.value = e.changedTouches[0].clientY;
    swipeY.value = e.changedTouches[0].clientY;
  };

  const handleSwiping = (e) => {
    transformUp.value = false;
    swipeY.value = e.touches[0].clientY;
  };

  const handleSwipeOff = () => {
    if (translateY.value > 100) {
      store.commit('player/setPlayerCollapse', true);
      setTimeout(() => {
        swipeStartY.value = 0;
        swipeY.value = 0;
      }, 300);
    } else {
      transformUp.value = true;
      swipeY.value = swipeStartY.value;
    }
  };

  return {
    swipeY,
    swipeStartY,
    transformUp,
    translateY,
    handleSwipeStart,
    handleSwiping,
    handleSwipeOff,
  };
}

<template>
  <div
    v-if="allForms.length"
    class="accreditation-requirements is-flex is-flex-direction-column"
  >
    <PossibleProfessions />
    <p class="fz-12 fw-300 py-2">{{ $t('accreditation.steps') }}</p>

    <ion-accordion-group ref="accordionGroup">
      <ion-accordion
        value="verification"
        mode="ios"
      >
        <ion-button
          slot="header"
          color="primary"
          class="uppercase ion-margin-bottom"
          :class="{ animated: !isConfirmedProfile }"
          :fill="isConfirmedProfile ? 'outline' : 'solid'"
          mode="ios"
          expand="block"
        >
          <div class="is-flex ion-align-items-center ion-justify-content-between w-100">
            <div class="is-flex ion-align-items-center">
              <IonIcon :icon="personCircleOutline" />
              <p class="pl-2">
                {{
                  !isConfirmedProfile
                    ? $t('accreditation.verifyBtn')
                    : $t('accreditation.verifyBtnConfirmed')
                }}
              </p>
            </div>

            <IonIcon
              v-if="isConfirmedProfile"
              :icon="checkmarkDone"
              color="success"
            />
          </div>
        </ion-button>

        <div
          class="ion-padding"
          slot="content"
        >
          <VerifyInformationModal
            :stream-id="streamId"
            :on-confirm="closeVerificationAccordion"
            :close="closeVerificationAccordion"
          />
        </div>
      </ion-accordion>
    </ion-accordion-group>

    <div
      v-if="!isConfirmedProfile"
      class="is-flex ion-padding-horizontal"
    >
      <IonIcon :icon="personCircleOutline" />
      <p class="pl-2 fz-12">{{ $t('accreditation.verifyPlaceholder') }}</p>
    </div>

    <div
      v-if="isConfirmedProfile"
      class="exam-btns"
    >
      <div
        v-if="examState.isPassed && isReviewPassed"
        class="is-flex ion-padding-horizontal"
      >
        <IonIcon :icon="medalOutline" />
        <p class="pl-2 fz-12">{{ $t('accreditation.congratulationPlaceholder') }}</p>
      </div>

      <AccreditationQuestions
        v-else-if="getFormResult('accreditation')"
        :is-accreditation-passed="isAccreditationPassed"
      />

      <SkippedPlayerTrackContainer v-if="!scrubbingDisabled" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { IonIcon, IonButton, IonAccordion, IonAccordionGroup } from '@ionic/vue';
import {
  personCircleOutline,
  checkmarkDone,
  medalOutline,
} from 'ionicons/icons';
import PossibleProfessions from '@/components/accreditation/PossibleProfessions';
import VerifyInformationModal from '@/components/accreditation/VerifyInformationModal';
import AccreditationQuestions from '@/components/accreditation/AccreditationQuestions';
import SkippedPlayerTrackContainer from "@/components/accreditation/SkippedPlayerTrackContainer";

import { useStore } from 'vuex';
import { onUnmounted, watch } from '@vue/runtime-core';
import isNil from "lodash/isNil";

const store = useStore();

const userResults = computed(() => store.state.questionnaires.userResults);
const examState = computed(() => {
  const formResults = userResults.value;
  const isCompleted = isNil(formResults.total_questions_count)
    ? null
    : formResults.total_questions_count === formResults.user_answered_questions_count;
  const isPassed = isNil(formResults.user_score_percentage)
    ? null
    : formResults.user_score_percentage >= formResults.passing_score_percentage;

  return { isCompleted, isPassed };
});

const isReviewPassed = computed(() => {
  const form = getFormResult('review');
  if (form) {
    const countQuestions = countQuestionsAnswers(form);
    return countQuestions.completedQuestions === countQuestions.allQuestions;
  }
  return false;
});

const allFormsResult = computed(() => store.state.questionnaires.allFormsResult);

const isConfirmedProfile = computed(() => store.state.accreditation.isConfirmedProfile);
const allForms = computed(() => store.state.questionnaires.allForms);
const streamId = computed(() => store.getters['player/getCurrentEpisode']?.id);
const scrubbingDisabled = computed(() => store.getters['player/getCurrentEpisode']?.disable_scrubbing);
const currentTimeIntervalId = computed(() => store.state.app.currentTimeIntervalId);

const getFormResult = (type) => {
  return allFormsResult.value.find((form) => form.type === type);
};

const countQuestionsAnswers = ((form) => {
  const answeredQuestions = form?.questions.filter((question) => question.answers.length && (question.is_correct !== false || !question.attempts_left));
  return {
    completedQuestions: answeredQuestions?.length,
    allQuestions: form?.questions.length,
  };
});

const isAccreditationPassed = computed(() => {
  const form = getFormResult('accreditation');

  if (form) {
    const countQuestions = countQuestionsAnswers(form);
    return  form.results.user_score_percentage >=  form.results.passing_score_percentage && countQuestions.completedQuestions === countQuestions.allQuestions;
  }

  return false;
});

const accordionGroup = ref(null);
function closeVerificationAccordion() {
  accordionGroup.value.$el.value = null;
};

watch(() => streamId.value, (newValue) => {
  if (newValue) {
    store.commit('questionnaires/resetStore');
    store
      .dispatch('questionnaires/getAllForms', newValue)
      .then((data) => store.dispatch('questionnaires/getAllFormsResult', data.forms));
  }
});

store.dispatch('questionnaires/getAllFormsResult');

watch(() => currentTimeIntervalId.value, (newValue) => {
  if (!newValue) {
    store.dispatch('app/startUpdateCurrentTime');
  }
});

if (!currentTimeIntervalId.value) {
  store.dispatch('app/startUpdateCurrentTime');
}

onUnmounted(() => {
  store.commit('app/resetCurrentTimeIntervalId');
});
</script>

<style lang="scss" scoped>
.accreditation-requirements {
  p {
    line-height: 1.2;
  }

  .button-left-content {
    display: flex;
    width: 100%;
  }

  ion-accordion {
    background-color: transparent;
  }
}

.full-height {
  height: calc(100dvh - 287px);
}

.animated {
  animation: shiny-btn 2s ease-in-out infinite;
}

@keyframes shiny-btn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 769px) {
  .full-height {
    height: calc(100dvh - 253px);
  }
}
</style>

import { ref, computed, onBeforeUnmount, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import replace from 'lodash/replace';
import { useStore } from 'vuex';

import {
  convertSecondsToTimeString,
  getSecondsFromHMSString,
} from '@/helpers/functions';
import {
  STREAM_PROGRESS_HISTORY_STORAGE_KEY,
  STREAM_PROGRESS_HISTORY_STORAGE_KEY_REGEX,
  STREAM_PROGRESS_HISTORY_STORAGE_TTL,
} from '@/config/constants';
import useAppStorage from '@/composables/useAppStorage';
import useFirebase8 from '@/composables/useFirebase8';

const TRACKING_INTERVAL = 15 * 1000; // 15s
const STREAM_COMPLETED_PART = 0.9; // 90%

export default function useStreamTracking() {
  const store = useStore();
  const { getKeyWithExpire, setKeyWithExpire, getKeys } = useAppStorage();
  const {
    initAuth: fireAuth,
    initDB: fireDB,
    getTimestampKey,
  } = useFirebase8();

  const streamTrackingData = ref(defaultTrackingData());
  const trackingInitialized = ref(false);
  const includeSeekPosition = ref(false);
  const trackingIntervalId = ref(null);
  const localProgress = ref({});
  const localStorageProgress = ref([]);
  const localStorageProgressFetched = ref(false);
  const dbProgress = ref([]);

  function defaultTrackingData() {
    return {
      hash: null,
      start: null,
      end: null,
      chapter_id: null,
      is_completed_at: null,
    };
  }

  const getLocalTimestamp = () => {
    return (
      (new Date(Date.now() - new Date().getTimezoneOffset() * 60000).getTime() /
        1000) |
      0
    );
  };

  const getRandomHash = () => uuidv4();

  const formattedLocalProgress = computed(() =>
    Object.values(localProgress.value),
  );

  const progressHistory = computed(() => [
    ...formattedLocalProgress.value,
    ...localStorageProgress.value,
    ...dbProgress.value,
  ]);
  watch(progressHistory, (newValue) => {
    store.commit('player/setProgressHistory', newValue);
  });

  const currentStreamSeconds = computed(
    () => store.state.player.trackInfo.trackCurrentTime,
  );
  const currentStreamDuration = computed(
    () => store.state.player.trackInfo.trackDuration,
  );
  const currentSeekPosition = computed(
    () => store.getters['player/getSeekPosition'],
  );
  const streamChapters = computed(
    () => store.getters['player/getCurrentEpisode']?.chapters ?? [],
  );
  const streamId = computed(
    () => store.getters['player/getCurrentEpisode']?.id ?? null,
  );

  const chaptersWithEndTimes = computed(() => {
    const chapters = cloneDeep(streamChapters.value);
    chapters.sort(
      (a, b) =>
        getSecondsFromHMSString(a.time_begin) -
        getSecondsFromHMSString(b.time_begin),
    );

    return chapters.map((chapter, index, chapters) => {
      if (!chapter.time_end) {
        const nextChapter = chapters[index + 1];
        if (nextChapter) {
          const newEndSeconds =
            getSecondsFromHMSString(nextChapter.time_begin) - 1;
          chapter.time_end = convertSecondsToTimeString(newEndSeconds);
        }
      }
      return chapter;
    });
  });

  const optimizeRanges = (
    parts,
    { startKey = 'start', endKey = 'end' } = {},
  ) => {
    const isAinB = (itemA, itemB) =>
      itemA[startKey] >= itemB[startKey] && itemA[endKey] <= itemB[endKey];

    const res = [];
    parts.forEach((el) => {
      const newEl = res.find((part) => isAinB(el, part));
      !newEl && res.push(el);
    });

    return res.map((el) => ({
      start: el[startKey],
      end: el[endKey],
    }));
  };

  const startTracking = async () => {
    // Reset state
    localProgress.value = {};
    streamTrackingData.value = defaultTrackingData();
    localStorageProgress.value = [];
    dbProgress.value = [];
    includeSeekPosition.value = true;
    localStorageProgressFetched.value = false;

    getLastStreamPositionStorage();
    fetchDBProgress(streamId.value);

    await getLocalStorageProgress(streamId.value);
    localStorageProgressFetched.value = true;

    trackingInitialized.value = true;
    initTrackingInterval();
    includeSeekPosition.value = false;
  };

  const getLastStreamPositionStorage = () => {
    const beProgress =
      store.getters['player/getCurrentEpisode']?.stream_user?.[0]?.end_time;

    const progress =
      store.state.player.recentlyPlayedProgress[streamId.value]?.progress;
    const result = progress ?? beProgress;

    if (result) {
      const lastPosition = getSecondsFromHMSString(result);
      store.commit('player/seekStream', lastPosition);
    } else {
      store.commit('player/seekStream', 0);
    }
  };

  const updateStorageLastPosition = () => {
    store.dispatch('player/updateRecentlyPlayedProgressStorage', {
      stream: streamId.value,
      progress: streamTrackingData.value.end ?? '00:00:00',
    });
  };

  const clearTrackingInterval = () => {
    if (trackingIntervalId.value) {
      clearInterval(trackingIntervalId.value);
      trackingIntervalId.value = null;
    }
  };

  const initTrackingInterval = (seekedTime, timeBeforeSeek) => {
    const logged = store.getters['authentication/isLogged'];

    if (!trackingInitialized.value || !logged) {
      return;
    }

    if (
      streamTrackingData.value.end &&
      streamTrackingData.value.start &&
      streamTrackingData.value.hash
    ) {
      if (timeBeforeSeek) {
        updateTrackingData(timeBeforeSeek);
        publishTrackingChapter();
      }
    }

    clearTrackingInterval();
    startNewTrackingChapter();
    publishTrackingChapter();
    updateStorageLastPosition();

    trackingIntervalId.value = setInterval(() => {
      updateTrackingData();
      publishTrackingChapter();
      updateStorageLastPosition();
    }, TRACKING_INTERVAL);
  };

  const startNewTrackingChapter = () => {
    let start = null;
    if (includeSeekPosition.value) {
      if (currentSeekPosition.value || currentSeekPosition.value === 0) {
        start = convertSecondsToTimeString(currentSeekPosition.value);
      } else {
        start = convertSecondsToTimeString(currentStreamSeconds.value);
      }
    } else {
      start = convertSecondsToTimeString(currentStreamSeconds.value);
    }

    const end = start;
    const newTrackingData = defaultTrackingData();
    newTrackingData.hash = getRandomHash();
    newTrackingData.start = start;
    newTrackingData.end = end;

    streamTrackingData.value = newTrackingData;
  };

  const updateTrackingData = (previousPlayerSeconds) => {
    streamTrackingData.value.end = previousPlayerSeconds
      ? convertSecondsToTimeString(previousPlayerSeconds)
      : convertSecondsToTimeString(currentStreamSeconds.value);

    if (streamTrackingData.value.end < streamTrackingData.value.start) {
      streamTrackingData.value.start = streamTrackingData.value.end;
    }

    const currentChapter = chaptersWithEndTimes.value.find((chapter) =>
      chapterPlaying(chapter),
    );

    if (
      streamTrackingData.value.chapter_id &&
      streamTrackingData.value.chapter_id !== currentChapter?.id
    ) {
      const prevChapterCompleted = chapterPlayed(
        chaptersWithEndTimes.value.find(
          (chapter) => chapter.id === streamTrackingData.value.chapter_id,
        ),
      );

      if (prevChapterCompleted) {
        streamTrackingData.value.is_completed_at = getLocalTimestamp();
        publishTrackingChapter();
        startNewTrackingChapter();
      }
    }

    streamTrackingData.value.chapter_id = currentChapter?.id ?? null;

    if (!currentChapter?.id || (currentChapter && !currentChapter.end_time)) {
      streamTrackingData.value.is_completed_at = isStreamCompleted()
        ? getLocalTimestamp()
        : null;
    }
  };

  const publishTrackingChapter = () => {
    const uid = fireAuth().getUid();
    const logged = store.getters['authentication/isLogged'];
    const user = store.getters['authentication/getUser'];

    if (!uid || !logged) {
      return;
    }

    const path = `progress/${streamId.value}/${uid}`;
    const key = String(streamTrackingData.value.hash);
    const payload = {
      ...streamTrackingData.value,
      timestamp: getTimestampKey(),
      user_id: user.id ?? null,
    };

    fireDB()
      .ref(path)
      .update({ [key]: payload });
    updateProgressLocal(key, payload);

    if (localStorageProgressFetched.value) {
      setLocalStorageProgress(streamId.value);
    }
  };

  const chapterPlaying = (chapter) => {
    if (
      getSecondsFromHMSString(chapter.time_begin) <
        currentStreamSeconds.value &&
      !chapter.time_end
    ) {
      return true;
    }
    return (
      getSecondsFromHMSString(chapter.time_begin) <
        currentStreamSeconds.value &&
      getSecondsFromHMSString(chapter.time_end) > currentStreamSeconds.value
    );
  };

  const chapterPlayed = (chapter) => {
    if (!chapter.time_end) {
      return false;
    }
    return (
      getSecondsFromHMSString(chapter.time_end) < currentStreamSeconds.value
    );
  };

  const isStreamCompleted = () => {
    if (currentStreamDuration.value <= 0) {
      return false;
    }

    const plannedCompletedSeconds =
      currentStreamDuration.value * STREAM_COMPLETED_PART;
    return currentStreamSeconds.value >= plannedCompletedSeconds;
  };

  const getLocalStorageProgress = async (streamId) => {
    const storageKey = replace(
      STREAM_PROGRESS_HISTORY_STORAGE_KEY,
      '{id}',
      streamId,
    );
    const progressStorage = await getKeyWithExpire(storageKey);

    if (progressStorage) {
      localStorageProgress.value = progressStorage;
    }
  };

  const setLocalStorageProgress = (streamId) => {
    const storageKey = replace(
      STREAM_PROGRESS_HISTORY_STORAGE_KEY,
      '{id}',
      streamId,
    );

    const progress = optimizeRanges(formattedLocalProgress.value);
    const commonProgress = concat(localStorageProgress.value, progress);
    setKeyWithExpire(
      storageKey,
      commonProgress,
      STREAM_PROGRESS_HISTORY_STORAGE_TTL,
    );
  };

  const updateProgressLocal = (key, payload) => {
    payload = cloneDeep(payload);
    payload.start = getSecondsFromHMSString(payload.start);
    payload.end = getSecondsFromHMSString(payload.end);
    localProgress.value[key] = payload;
  };

  const clearExpiredStorageProgress = async () => {
    const storageKeys = await getKeys();
    if (storageKeys?.keys) {
      storageKeys.keys.forEach((key) => {
        if (STREAM_PROGRESS_HISTORY_STORAGE_KEY_REGEX.test(key)) {
          getKeyWithExpire(key);
        }
      });
    }
  };

  const fetchDBProgress = (streamId) => {
    store
      .dispatch('player/getStreamUserProgress', {
        userId: store.getters['authentication/getUser'].id,
        streamId,
      })
      .then((data) => {
        dbProgress.value = optimizeRanges(data.progress, {
          startKey: 'start_time',
          endKey: 'end_time',
        }).map((part) => ({
          ...part,
          start: getSecondsFromHMSString(part.start),
          end: getSecondsFromHMSString(part.end),
        }));
      });
  };

  // Cleanup on component unmount
  onBeforeUnmount(() => {
    clearTrackingInterval();
  });

  return {
    // State
    streamTrackingData,
    trackingInitialized,
    localProgress,
    localStorageProgress,
    dbProgress,
    progressHistory,
    currentSeekPosition,

    // Methods
    startTracking,
    clearTrackingInterval,
    initTrackingInterval,
    clearExpiredStorageProgress,
  };
}

<template>
  <div class="is-flex ion-justify-content-between ion-align-items-center">
    <AppImg
      :img-src="banner"
      img-alt="banner"
      border-radius="5px"
      class="img-wrap"
    />
    <div class="track-description">
      <p
        class="title fz-14 fw-500 mb-2 one-line"
        v-html="title"
      >
      </p>

      <p
        class="fz-12 fw-300 pb-2 one-line"
        v-html="subtitle"
      >
      </p>
    </div>
    <slot name="end" ></slot>
  </div>
</template>

<script>
import {computed} from "@vue/reactivity";
import {getSmallestSizeImage} from "@/helpers/functions";
import {CATEGORY_PODCAST_AUDIO} from "@/config/constants";
import AppImg from '@/components/common/AppImg.vue';

export default {
  name: "EpisodePreview",
  components: {
    AppImg,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    imgSrc: {
      type: String,
      default: null,
    },
    podcastMedia: {
      type: Array,
      default: () => [],
    },
    courseMedia: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const banner = computed(() => {
      if (props.imgSrc) {
        return props.imgSrc;
      }
      const media = getSmallestSizeImage(props.podcastMedia.filter(m => m.category !== CATEGORY_PODCAST_AUDIO))?.url ?? null;
      const mediaFallback = getSmallestSizeImage(props.courseMedia)?.url ?? null;
      return media || mediaFallback;
    });


    return { banner };
  },
};
</script>

<style scoped lang="scss">
.track-description {
  overflow: hidden;
  padding-left: 10px;
}

.one-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.img-wrap {
  min-width: 50px;
  width: 50px;
  height: 50px;
  margin-bottom: 8px;

  :deep(img) {
    height: 100%;
    width: 100%;
  }
}
</style>

<template>
  <div
    v-if="!isLoading && formType === 'accreditation'"
    class="tablet-centered"
  >
    <div class="is-flex ion-justify-content-end">
      <ion-button
        v-if="examState.isCompletedAllQuestion && showEmailCertificate"
        class="is-relative"
        color="primary"
        mode="ios"
        @click="emailCertificate"
      >
        <ion-icon
          slot="end"
          :icon="mailOutline"
        />
        {{ $t('examLeaveModal.emailCertificate') }}
      </ion-button>
      <ion-button
        v-if="showRetryFormBtn"
        class="is-relative"
        color="warning"
        mode="ios"
        :disabled="isResettingFormLoader"
        @click="retryForm"
      >
        {{ $t('streams.retryForm') }}
      </ion-button>
    </div>
  </div>

  <div
    v-if="isLoading"
    class="is-flex ion-align-items-center ion-justify-content-center h-100"
  >
    <IonSpinner />
  </div>

  <div
    v-else
    class="ion-padding-start ion-padding-end pt-5"
  >
    <div class="ion-padding-top tablet-centered h-100">
      <AccreditationExam
        :form="form"
        :questions-result="openQuestions"
        :stream-id="streamId"
        :hide-correct-answers="hideCorrectAnswers"
        :key="resetExamComponentKey"
        :always-show-questions="formType !== 'accreditation'"
        v-model:questionsCount="openQuestionsCount"
        @new-question="onNewQuestion"
        @init:new-question="onNewQuestion"
      />
    </div>
  </div>
</template>

<script setup>
import AccreditationExam from '@/components/accreditation/AccreditationExam';
import VerifyInformationModal from '@/components/accreditation/VerifyInformationModal';
import { mailOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n/index';
import useNotificationSound from "@/composables/sound/useNotificationSound";

import {
  IonButton,
  IonIcon,
  IonSpinner,
  modalController,
} from '@ionic/vue';
import { computed, onUnmounted, ref, defineProps, watch } from 'vue';
import { useStore } from 'vuex';
import isNil from "lodash/isNil";
import useRequestToast from "@/composables/useRequestToast";

const store = useStore();
const { t } = useI18n();
const { openSuccessToast, openErrorToast } = useRequestToast();
const { playNotification: playNotificationSound } = useNotificationSound();
const props = defineProps({
  isAccreditationPassed: {
    type: Boolean,
    default: false,
  },
});

const isLoading = ref(false);
const isResettingFormLoader = ref(false);
const isError = ref(false);
const openQuestionsCount = ref(0);
const resetExamComponentKey = ref(0);
const allFormsResult = computed(() => store.state.questionnaires.allFormsResult);
const reviewForm = computed(() => allFormsResult.value.find((form) => form.type === 'review') || null);
const form = computed(() => props.isAccreditationPassed ? reviewForm.value : store.state.questionnaires.formAccreditation);

const profileDetailsFetched = computed(() => store.getters['profile/profileDetailsFetched']);
const userAnswers = computed(() => store.state.questionnaires.userAnswers);
const userResults = computed(() => store.state.questionnaires.userResults);
const formType = computed(
  () => store.state.questionnaires.formAccreditation?.type,
);
const courseId = computed(() => store.state.questionnaires.courseId);
const streamId = computed(() => store.getters['player/getCurrentEpisode']?.id);

const isExamResetTimesGrouped = computed(() => !!form.value?.reset_grouped);

const examState = computed(() => {
  const formResults = userResults.value;
  const isCompleted = isNil(formResults.total_questions_count)
    ? null
    : formResults.total_questions_count === formResults.user_answered_questions_count;

  const isCompletedAllQuestion = isNil(formResults.medicast_total_questions_count)
    ? null
    : formResults.medicast_total_questions_count === formResults.medicast_user_answered_questions_count;

  const isPassed = isNil(formResults.user_score_percentage)
    ? null
    : formResults.user_score_percentage >= formResults.passing_score_percentage;

  return { isCompleted, isCompletedAllQuestion, isPassed };
});

const showRetryFormBtn  = computed(() => {
  if (!isExamResetTimesGrouped.value) {
    return;
  }

  const hasAttempts = form.value.attempts_left > 0;
  return examState.value.isCompleted && !examState.value.isPassed && hasAttempts;
});

const hideCorrectAnswers = computed(() => {
  return isExamResetTimesGrouped.value && (!(examState.value.isCompleted && examState.value.isPassed));
});

const openQuestions = computed(() => {
  return userAnswers.value;
});

const showEmailCertificate = computed(() => examState.value.isPassed);

const getData = async () => {
  try {
    isLoading.value = true;

    await store.dispatch('questionnaires/getForm', {
      streamId: streamId.value,
      formId: form.value.id,
    });

    await store.dispatch(
      'questionnaires/getUserResponses',
      form.value.id,
    );
    isLoading.value = false;
  } catch (error) {
    isError.value = true;
    isLoading.value = false;
  }
};

watch(() => props.isAccreditationPassed, () => {
  if (reviewForm.value) {
    getData();
  }
});

const openModal = async (componentName, componentClass) => {
  const modal = await modalController.create({
    component: componentName,
    cssClass: componentClass,
    componentProps: {
      streamId: +streamId.value,
    },
  });
  await modal.present();
};

const openPlayer = (id, course) => {
  const startAt = course.streams.findIndex((stream) => stream.id === id);
  store.dispatch('player/openPlayer', {
    course,
    company: course.company,
    playlist: course.streams,
    startAt,
    collapse: true,
  });
};

getData().then(() => {
  if (!profileDetailsFetched.value && !isError.value) {
    store.dispatch('profile/setUserProfessions')
      .then(() => {
        openModal(VerifyInformationModal, 'verify-information-modal');
      });
    store.dispatch('course/setCourseData', courseId.value)
      .then((course) => {
        openPlayer(+streamId.value, course);
      });
  }
});

if (!store.state.app.currentTimeIntervalId) {
  store.dispatch('app/startUpdateCurrentTime');
}

const retryForm = async () => {
  isResettingFormLoader.value = true;
  try {
    await store.dispatch('questionnaires/resetUserAnswerLastAttempt', { formId: form.value.id });
    await store.dispatch('questionnaires/getExamResults', parseInt(streamId.value));
    store.commit('questionnaires/clearAccreditationAnswersOnRetry');

    resetExamComponentKey.value++;
    // eslint-disable-next-line no-empty
  } catch (e) {} finally {
    isResettingFormLoader.value = false;
  }
};

const emailCertificate = () => {
  store.dispatch('questionnaires/sendCertificateToUserEmail', form.value.id)
    .then(() => {
      openSuccessToast(t('profile.emailSentMessage'));
    }).catch((error) => {
      openErrorToast(error);
    });
};

const onNewQuestion = () => {
  playNotificationSound();
};

onUnmounted(() => {
  store.commit('app/resetCurrentTimeIntervalId');
});
</script>

<style lang="scss" scoped>
</style>

<template>
  <div
    v-if="userResults.form_id"
    :class="{ pointer: hasOpenedUnansweredQuestions }"
    @click="scrollToFirstUnansweredQuestion()"
  >
    <template v-if="!progressOnly">
      <div
        v-if="userResults.user_answered_questions_count"
        class="exam-progress is-flex"
      >
        <div
          class="wrong-bar is-flex ion-align-items-center ion-justify-content-center bar-transition"
          :style="{ width: `${wrongScore}%`}"
        >
          <p v-if="wrongScore">{{ wrongScore + '%' }}</p>
        </div>
        <div
          class="success-bar is-flex ion-align-items-center ion-justify-content-center bar-transition"
          :style="{ width: `${successScore}%`}"
        >
          <p v-if="successScore">{{ successScore + '%' }}</p>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="exam-progress is-flex">
        <div
          class="is-flex general-bar ion-align-items-center ion-justify-content-center bar-transition"
          :style="{ width: `${answeredPercentage}%`}"
        >
          <div
            v-if="answeredPercentage"
            class="ion-text-center"
          ></div>
        </div>
      </div>
    </template>

    <div class="is-flex ion-justify-content-between pt-2">
      <ion-text
        class="fz-12 text-gray"
      >
        <p v-if="hasOpenedUnansweredQuestions">{{ $t('accreditation.clickOnProgressBar') }}</p>
      </ion-text>
      <ion-text
        color="light"
      >
        <p>{{ progressCount }}</p>
      </ion-text>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, defineProps } from 'vue';
import { IonText } from '@ionic/vue';
import { useI18n } from 'vue-i18n/index';
import { useStore } from 'vuex';

const props = defineProps({
  progressOnly: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const store = useStore();

const userResults = computed(() => store.state.questionnaires.userResults);

const wrongScore = computed(() => {
  const result = userResults.value.user_answered_questions_count * 100 / userResults.value.total_questions_count - userResults.value.user_score_percentage;
  return result ? result.toFixed(2): 0;
});
const successScore = computed(() => {
  const result = userResults.value.user_score_percentage;
  return result ? result.toFixed(2): 0;
});
const progressCount = computed(() => {
  return (userResults.value.medicast_user_answered_questions_count || 0)
  + '/'
  + (userResults.value.medicast_total_questions_count || 0) + ' ' + t('accreditation.questionAnswered');
});

const answeredPercentage = computed(() => {
  const result = userResults.value.medicast_user_answered_questions_count * 100 / userResults.value.medicast_total_questions_count;
  return result ? result.toFixed(2): 0;
});

const hasOpenedUnansweredQuestions = computed(() => {
  const openedUnansweredQuestions = store.state.questionnaires.openedQuestions.filter(q => !q.answered);
  return openedUnansweredQuestions.length > 0;
});

function scrollToFirstUnansweredQuestion () {
  if (!hasOpenedUnansweredQuestions.value) {
    return;
  }

  const questions = document.querySelectorAll('.form-question');

  for (const question of questions) {
    const validChild = question.querySelector('[item-name="answer"]:not(.opacity-50)');
    if (validChild) {
      question.scrollIntoView({ behavior: 'smooth', block: 'center' });
      break;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/theme/custom";

  .exam-progress {
    height: 40px;
    border-radius: 6px;
    overflow: hidden;
    background: var(--grey);
    color: var(--white);

    @media (max-width: $tablet-breakpoint) {
      height: 30px;
    }
  }

  .wrong-bar {
    background: var(--ion-color-danger);
  }

  .success-bar {
    background: var(--ion-color-success);
    border-radius: 0 6px 6px 0 ;
  }

  .general-bar {
    background: var(--ion-color-light);
    border-radius: 0 6px 6px 0 ;
    color: var(--ion-color-dark);
  }

  .bar-transition {
    transition: width 0.5s;
  }

  .text-gray {
    color: #bfbfbf;
  }
</style>
